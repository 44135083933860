import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "../styles/Services.css";

function ServGallery() {
  const image1 = ["/images/sgall.jpg"];
  const image2 = ["/images/sgall3.jpg"];
  const image3 = ["/images/sgall2.jpg"];

  return (
    <div className="s-gallery-container">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry className="masonry" gutter="2px">
          {image1.map((image, i) => (
            <img
              key={"1" + i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt="gallery of designs"
            />
          ))}
          <div className="s-gallery-card">
            <h5>CONSTRUCTION DESIGN OVERSIGHT.</h5>
            <p>
              Coordination between the builder, contractors, and the interior
              designer is key to ensuring a smooth and predictable construction
              process.  During this phase, I work hand in hand with the client
              and builder to ensure that the vision is properly executed.  From
              cabinets to countertops, flooring to fixtures, tile to paint,
              lighting to carpet, each component is intentionally and carefully
              selected and executed to not only meet the design vision, but
              ensure a highly functional, cohesive, and lasting space.{" "}
            </p>
          </div>
          {image2.map((image, i) => (
            <img
              key={"2" + i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt="gallery of designs"
            />
          ))}
          <div className="s-gallery-card">
            <h5>CONSULTATION, SCOPING, AND DESIGN.</h5>
            <p>
              From the start, the goal of our entire design project is to
              creatively collaborate to compose your vision in true form and
              function.  This relies on a thorough and complete understanding of
              your (the client's) goals, budget, scope, wants, needs, and "must
              haves." Once jointly defined, I can bring to bear my extensive
              knowledge and experience to actualize a design solution.  ​
            </p>
          </div>
          {image3.map((image, i) => (
            <img
              key={"3" + i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt="gallery of designs"
            />
          ))}
          <div className="s-gallery-card">
            <h5>FINISH, FURNISH, AND POLISH.</h5>
            <p>
              The final stage, and perhaps the most exciting, is bringing it all
              together.  A harmonious design solution demands that every detail
              be attended.  I will work to bring all of the finishing elements
              together to create a beautiful, cohesive living space.  From sofas
              to tables, carpets to lamps, pillows to throws, each and every
              element is carefully selected to ensure that the spaces, surfaces,
              textures, materials, light and color all blend together to achieve
              the ultimate goal - breathtaking spaces.
            </p>
          </div>
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default ServGallery;
