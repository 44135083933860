import React from "react";
import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "../styles/Contact.css";

const useLocalStorageState = ({ key, defaultValue = false }) => {
  const [state, setState] = useState(
    () => JSON.parse(window.localStorage.getItem(key)) || defaultValue
  );

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state), [key, state]);
  });

  return [state, setState];
};

function Contact() {
  const [showForm, setShowForm] = useLocalStorageState("show-form", false);
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y0mkj96",
        "template_c6n3bkc",
        e.target,
        "user_yNizOFYgUpBQLTz0dKs06"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowForm(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  if (!showForm) {
    return (
      <div className="contact-container">
        <div className="title-container">
          <h1>Let's Chat!</h1>
          <h2>Tell me more about your goals!</h2>
        </div>
        <div className="form-container">
          <Form className="form-body" onSubmit={handleSubmit}>
            <div className="section-body">
              <div className="first-column">
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <br />
                  <Form.Control
                    className="text-input"
                    type="text"
                    name="first-name"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    name="last-name"
                    className="text-input"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    name="email"
                    className="text-input"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    name="subject"
                    className="text-input"
                  />
                </Form.Group>
              </div>
              <div className="second-column">
                <Form.Group>
                  <Form.Label>Tell me more about your project...</Form.Label>
                  <br />
                  <Form.Control
                    className="text-area-input"
                    as="textarea"
                    rows={10}
                    name="project-description"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="form-button">
              <Button className="button" type="submit">
                SUBMIT
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="contact-container">
        <div className="title-container">
          <h1>Thank you for submitting your request!</h1>
          <h2>I look forward to working with you!</h2>
        </div>
      </div>
    );
  }
}

export default Contact;
