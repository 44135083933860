import React from "react";
import "../styles/About.css";

function About() {
  return (
    <div className="about-container">
      <div className="about-text">
        <h1 className="about-title">ABOUT EAST BAY DESIGN</h1>
        <p>
          When I began college, I wish someone, anyone, would have told me that
          Interior Design was a thing.{" "}
          <b>I put far more passion into decorating dorm rooms</b>, than into my
          major!
          <p>
            So, many beautiful dorm rooms later (and after graduating from the
            University of Washington with a bachelor's degree in European
            History) I took a job as a Flight Attendant with Delta Airlines to
            travel the world. After a spell with Delta, and a stint as a FA
            Supervisor/Instructor with Alaska Airlines, my husband and I settled
            down in Beaverton, OR, where I worked in Management for Nike.
          </p>
          <p>
            Twenty-seven years, four kids, and six houses later, my husband
            (same husband) and I now live in Gig Harbor, WA, and Bend OR, where
            <b>
              I have finally found my way back, full circle, to a true passion,
              Interior Design.
            </b>
          </p>
          <p>
            <b>
              I love what I do. Working hand in hand with my clients, designing
              spaces, selecting textures, materials, surfaces, light, and color
              to create unique and beautiful design solutions... ALL of it
              brings me joy.
            </b>{" "}
            And I can now say, Interior Design is not only a thing,{" "}
            <b>it's my thing.</b>
          </p>
          <p>
            Thanks for following my journey. I look forward to working with you!{" "}
          </p>
          <p>
            <b>Mary Gilbert, Interior Designer, East Bay Design</b>
          </p>
        </p>
      </div>
      <div className="kid-container">
        <img src="./images/kids.jpg" alt="Mary and her family" />
      </div>
    </div>
  );
}

export default About;
