import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <h5>GIG HARBOR</h5>
      <h5>&#x2022;</h5>
      <h5>BEND</h5>
    </div>
  );
}

export default Footer;
