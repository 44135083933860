import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import "../styles/Gallery.css";

function Gallery() {
  const image_first = [
    "/images/gallery_1.jpg",
    "/images/gallery_2.jpg",
    "/images/gallery_5.jpg",
    "/images/gallery_3.jpg",
  ];

  const image_second = [
    "/images/gallery_4.jpg",
    "/images/gallery_6.jpg",
    "/images/gallery_8.jpg",
    "/images/gallery_7.jpg",
  ];

  return (
    <div className="gallery-container">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="4px">
          {image_first.map((image, i) => (
            <img
              key={"1" + i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt="gallery of designs"
            />
          ))}
          <div className="gallery-card">
            <h4>Personalized</h4> <h4>Timeless</h4> <h4>Design.</h4>
          </div>
          {image_second.map((image, i) => (
            <img
              key={i}
              src={image}
              style={{ width: "100%", display: "block" }}
              alt="gallery of designs"
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default Gallery;
