import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";
import "../styles/Carousel.css";

function Carousel({ images }) {
  console.log("images" + images);
  console.log("swiper" + Swiper);
  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        className="house-tour-slider"
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={item} alt="gallery of house" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default Carousel;
