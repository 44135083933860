import "./App.css";
import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { bendImages, ghImages, fiImages } from "./assets";

function App() {
  const [images, setImages] = useState([]);

  const handleBendClick = () => {
    setImages([]);
    let imageCopy = bendImages;
    setImages(imageCopy);
  };

  const handleGigClick = () => {
    setImages([]);
    setImages(ghImages);
  };

  const handleFoxClick = () => {
    setImages([]);
    setImages(fiImages);
  };

  const handleReturn = () => {
    setImages([]);
  };

  return (
    <div className="App">
      <Router>
        <Navbar onClick={handleReturn} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/projects"
            element={
              <Projects
                handleBendClick={handleBendClick}
                handleGigClick={handleGigClick}
                handleReturn={handleReturn}
                handleFoxClick={handleFoxClick}
                images={images}
              />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
