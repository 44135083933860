export const bendImages = [
  require("./images/gallery_2.jpg"),
  require("./images/gallery_1.jpg"),
  require("./images/gallery_3.jpg"),
  require("./images/gallery_4.jpg"),
  require("./images/bend_projects.jpg"),
  require("./images/gallery_6.jpg"),
  require("./images/gallery_7.jpg"),
  require("./images/gallery_8.jpg"),
];

export const ghImages = [
  require("./images/tour_eb.jpg"),
  require("./images/tour_eb1.jpg"),
  require("./images/tour_eb2.jpg"),
];

export const fiImages = [
  require("./images/FI_1.jpg"),
  require("./images/FI_3.jpg"),
  require("./images/FI_4.jpg"),
  require("./images/FI_5.jpg"),
  require("./images/FI_6.jpg"),
  require("./images/FI_8.jpg"),
  require("./images/FI_9.jpg"),
  require("./images/FI_10.jpg"),
  require("./images/FI_11.jpg"),
  require("./images/FI_12.jpg"),
  require("./images/FI_13.jpg"),
  require("./images/FI_15.jpg"),
  require("./images/FI_16.jpg"),
];
