import React from "react";
import "../styles/Projects.css";
import Carousel from "../components/Carousel";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

function Projects({
  handleReturn,
  handleBendClick,
  handleGigClick,
  handleFoxClick,
  images,
}) {
  if (images.length > 0) {
    return (
      <div className="carousel-container">
        <div className="carousel">
          <Carousel images={images} />
        </div>
        <div className="return-container">
          <button className="return-button" onClick={handleReturn}>
            Return to Projects
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="project-container">
        <div className="overview-container">
          <h1 className="project-title">East Bay Design Projects</h1>
          <div className="description">
            <p>
              With an intense focus on detail, I design every space to reflect
              the styles of my clients, while also maintaining a level of
              timelessness. At the end of the day, my goal as an interior
              designer is to create a beautifully cohesive, comfortable, and
              unique space that reflects who you are. So you'll feel at home,
              whether you're moving into in a brand-new place or re-imagining
              your current space.{" "}
            </p>
            <p>
              If you'd like to hear more about my services, or you'd like to
              contact me for a quote, send me an email at
              <em> marygilbertdesign@gmail.com</em> or contact me using the
              <b> Contact</b> page in the menu above. Thank you!
            </p>
          </div>
        </div>
        <div className="project-list-container">
          <h2>Bend, Oregon</h2>
          <p>
            Rustic urban meets timeless traditional styles in this custom built
            home located just outside of Bend, Oregon. Designed by myself and
            architect Jim Rozewski, each uniquely classic detail within this
            home flows together seamlessly.
          </p>
          <p>Check out more photos by clicking the image below.</p>
          <div className="image-container">
            <img
              className="project-list-photo"
              src="/images/bend_projects.jpg"
              alt="Home in bend oregon"
              onClick={handleBendClick}
            />
            <h2>Gig Harbor, Washington</h2>
            <p>
              A nostalgic Cape Cod costal-style meets Pacific Northwest with
              this custom-built, waterfront home located in Gig Harbor,
              Washington. 
            </p>
            <p>View more images by clicking the picture below.</p>
            <div className="image-container">
              <img
                className="project-list-photo"
                src="/images/easy_bay_main.jpg"
                alt="Home in Gig Harbor"
                onClick={handleGigClick}
              />
            </div>
            <h2>Fox Island, Washington</h2>
            <p>
              As one of my first and most beloved interior design projects, this
              home custom-built on Fox Island combined my love for costal
              styles, with a timeless elegance, and an undeniable coziness.  To
              view more pictures, click the image below! 
            </p>
            <p>To view more pictures, click the image below! </p>
            <div className="image-container">
              <img
                className="project-list-photo"
                src="/images/FI_1.jpg"
                alt="Home in Gig Harbor"
                onClick={handleFoxClick}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
