import React from "react";
import Welcome from "../components/Welcome";
import Gallery from "../components/Gallery";

function Home() {
  return (
    <div>
      <Welcome />
      <Gallery />
    </div>
  );
}

export default Home;
