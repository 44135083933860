import React from "react";
import "../styles/Services.css";
import ServGallery from "../components/ServGallery";

function Services() {
  return (
    <div className="services-container">
      <h1 className="s-title">SERVICES</h1>
      <h2 className="s-h2">
        MAKE YOUR DREAM HOME A REALITY WITH EAST BAY DESIGN
      </h2>
      <ServGallery />
    </div>
  );
}

export default Services;
