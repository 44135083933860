import React from "react";
import "../styles/Welcome.css";
function Welcome() {
  return (
    <div className="welcome-container">
      <div className="image-container">
        <img
          src="/images/main_bend.jpg"
          alt="Main floor of home in Bend designed by Mary Gilbert"
        />
      </div>
      <div className="title-container">
        <div className="title">
          <h1>WELCOME TO</h1>
          <h1 className="east-bay">EAST BAY DESIGN</h1>
        </div>
        <h5>INTERIOR DESIGN BY MARY GILBERT</h5>
      </div>
    </div>
  );
}

export default Welcome;
